import Featuredmentor from '../Featuredmentor/Featuredmentor';
import React from 'react';
import './Featuredmentorsbox.css';
import { useTriggers } from '../../hooks/navigation';
import useMentors from '../../hooks/api/mentors/useMentors';
import { useSelector } from 'react-redux';
import MentorPagination from '../../modules/mentors/pagination';
import { useTranslation } from 'react-i18next';

const Featuredmentorsbox = (props) => {
  const { t } = useTranslation();
  const { handleModalCloseClick, handleSeeAllMentorsClick } = useTriggers();
  const auth = useSelector((state) => state.auth.data);
  const { loading, handlePreviousPage, handleNextPage, page } =
    useMentors(true);
  const mentors = useSelector((state) => state.mentors);

  return (
    <div className="modal featured-mentors-modals">
      <div className="w-layout-vflex modal-container prompt-gallery-container featured-mentor-container">
        <div className="w-layout-hflex modal-header featured-mentor-modal-header">
          <h3 className="modal-header-title">{t('Featured Mentors')}</h3>
          <a className="default-btn prompt-add-btn w-button" href="#">
            {t('+ Add New Prompt')}
          </a>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
          {auth?.tenant?.is_admin && (
            <div
              className="featured-mentor-see-all-btn"
              onClick={handleSeeAllMentorsClick}
              prop-events-value-onclick="handleSeeAllMentorsClick"
            >
              {t('See All')}
            </div>
          )}
        </div>
        <div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
          <div className="w-layout-vflex prompt-category-container featured-mentors-container">
            <div className="w-layout-grid ai-expert-grid ai-expert-grid-modal">
              {mentors?.featured?.results?.map((item) => (
                <Featuredmentor mentor={item} key={item.slug} />
              ))}
            </div>

            {mentors?.featured?.results?.length === 0 && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                {' '}
                {t('No mentor is marked as Featured')}
              </div>
            )}

            <div className="featured-mentors-pagination">
              <MentorPagination
                mentors={mentors?.featured}
                page={page}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featuredmentorsbox;
