import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function useEditMentorTabs() {
  const edit = useSelector((state) => state.mentors.edit);
  const [tab, setTab] = useState(edit?.activeTab || 'settings');
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const { t } = useTranslation();
  const tabs = [
    { key: 'settings', title: t('Settings') },
    { key: 'llm', title: t('LLM') },
    { key: 'prompts', title: t('Prompts') },
  ];

  if (editMentor?.can_use_tools) {
    tabs.push({ key: 'tools', title: t('Tools') });
  }

  tabs.push({ key: 'datasets', title: t('Datasets') });
  tabs.push({ key: 'embed', title: t('Embed') });

  return { tab, setTab, tabs };
}
