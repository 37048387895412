import React, { useEffect, useState } from 'react';
import { authActions } from '../../../lib/redux/auth/slice';
import { useDispatch } from 'react-redux';
import Chat from './share-chat';
import PageLoader from '../../loader/page-loader';
import { redirectToAuth } from '../../../utils/auth';
import Header from '../../../components/Header/Header';

export default function ShareChat() {
  return (
    <div className="body">
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/custom-styles.css);
          @import url(/css/normalize.css);
          @import url(/css/components.css);
          @import url(/css/ibl-aimentorpro-v2.css);
          @import url(/custom-assets/custom.css);


          @supports (-webkit-overflow-scrolling: touch) {
             input:focus, textarea:focus {
               font-size: 16px;
             }
          }



          .guided-question-block-old{
          	width:calc(100% - 30px)!important;
          }
          body.dark-mode .modal .modal-container{
          	border: solid 1px #ffffff75;
          }
          .menu-container .menu-element-block.current .menu-element-bt-border{
          	border-color: #2467eb!important;
          }
          .ai-response-text-container :is(h1, h2, h3, h4, h5, h6){
          	margin-top:0px;
            margin-bottom:0px;
          }
          .ai-response-text-container pre {
              display: block;
              padding: 9.5px;
              margin: 0 0 10px;
              font-size: 13px;
              line-height: 1.42857143;
              color: #333;
              word-break: break-all;
              word-wrap: break-word;
              background-color: #f5f5f5;
              border: 1px solid #ccc;
              border-radius: 4px;
          }
          .ai-response-container-content{
            word-break: break-word;
          }
          .ai-response-text-container td, .ai-response-text-container th {
              background-color: #f9f9f9;
              padding: 8px;
              line-height: 1.42857143;
              vertical-align: top;
              border-top: 1px solid #ddd;
          }
          .ai-response-text-container th{
          	  vertical-align: bottom;
              border-bottom: 2px solid #ddd;
          }
          .ai-response-text-container img {
              max-width: 35%;
          }
          .audio-chat-mentor-image.is-speaking {
            animation: zoomInOut 2s linear infinite;
          }
          @keyframes zoomInOut {
            0% {
              transform: scale3d(1, 1, 1);
            }
            50% {
              transform: scale3d(0.93, 0.93, 0.93);
            }
            100% {
              transform: scale3d(1, 1, 1);
            }
          }
        `,
        }}
      />
      <div className="w-layout-hflex site-container">
        <Chat />
      </div>
    </div>
  );
}
