import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { useEffect, useState } from 'react';
import { chatActions } from '../../lib/redux/chat/slice';
import { toast } from 'react-toastify';

let mediaRecorder;
let audioChunks = [];
let stream = null;

export default function useVoiceChat() {
  const auth = useSelector((state) => state.auth.data);
  const chat = useSelector((state) => state.chat.data);
  const [recording, setRecording] = useState(false);
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();

  const convertVoiceToText = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/audio-to-text/`;
    const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
    const formData = new FormData();
    formData.append('file', audioBlob, 'recording.webm');
    setProcessing(true);
    const response = await api.post(url, formData, true);
    if (response?.data) {
      dispatch(
        chatActions.chatUpdated({ ...chat, prompt: response.data.text })
      );
    } else {
      toast.error('Could not process your audio, please try again');
    }
    setProcessing(false);
    stream.getTracks().forEach((track) => track.stop());
  };

  const startRecording = async () => {
    audioChunks = [];
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.onstop = convertVoiceToText;
    mediaRecorder.start();
  };

  const handleMicrophoneBtnClick = async () => {
    if (recording) {
      mediaRecorder.stop();
      setRecording(false);
    } else {
      await startRecording();
      setRecording(true);
    }
  };

  useEffect(() => {
    dispatch(chatActions.recordingUpdated(recording));
  }, [recording]);

  useEffect(() => {
    dispatch(chatActions.processingRecordingUpdated(processing));
  }, [processing]);

  return { handleMicrophoneBtnClick, recording, processing };
}
