import React from 'react';
import './Creatementormodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import useCreateMentor from '../../hooks/api/mentors/useCreateMentor';
import Loader from '../../modules/loader';
import { userIsOnTrial } from '../../utils/shared';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { MentorPrompts } from '../../modules/mentors/edit';
import { useTranslation } from 'react-i18next';

const Creatementormodalbox = (props) => {
  const { t } = useTranslation();
  const { handleModalCloseClick } = useTriggers();
  const {
    loading,
    handleChange,
    handleCheckChange,
    handleCreateMentor,
    handleFileChange,
    mentorImgSrc,
    mentor,
    handleTriggerFileUpload,
    handleRemoveImage,
    mentorCategories,
    tab,
    setTab,
    tabs,
    setMentor,
    isMentorFormValid,
  } = useCreateMentor();

  return (
    <div className="modal mentor-creation-modal">
      <div className="w-layout-vflex modal-container prompt-gallery-container edit-mentor-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{t('Create Mentor')}</h3>
          <a className="default-btn prompt-add-btn w-button" href="#">
            {t('+ Add New Prompt')}
          </a>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
          <div className="w-layout-hflex menu-container data-source-types-menu-block">
            {tabs?.map((item) => {
              const disabled = item.key !== 'settings' && !isMentorFormValid();
              return (
                <a
                  className={`menu-element-block w-inline-block ${item.key === tab ? 'current' : ''}`}
                  data-w-id="ca621093-7a00-bd53-ecc7-48d2e28e9363"
                  key={item.key}
                  onClick={!disabled ? () => setTab(item.key) : () => {}}
                  href="#"
                  style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                >
                  <div className="text-block-47 active-text">{item.title}</div>
                  <div
                    className={`menu-element-bt-border ${item.key === tab ? 'active-element-bt' : ''} `}
                  ></div>
                </a>
              );
            })}
          </div>
          <div className="w-layout-vflex edit-mentor-tabs-container">
            {tab === 'settings' && (
              <div className="w-layout-vflex edit-mentor-setting-tab">
                <div className="secret-key-form-block edit-mentor-settings-form-block w-form">
                  <form
                    className="manage-account-form secret-key-form edit-mentor-settings-form"
                    data-name="Email Form 2"
                    data-wf-element-id="3d85f56c-c65d-b956-7f38-bd18ab5e5b92"
                    data-wf-page-id="650305782bc61751e5765214"
                    id="email-form-2"
                    method="get"
                    name="email-form-2"
                  >
                    <div className="w-layout-hflex flex-block-81">
                      <div className="w-layout-vflex flex-block-82">
                        <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block">
                          <div className="w-layout-hflex flex-block-19">
                            <label
                              className="form-input-label"
                              htmlFor="name-6"
                            >
                              {t('Name')}
                            </label>
                            <label
                              htmlFor="name-6"
                              className="form-input-label required"
                            >
                              *
                            </label>
                          </div>
                          <input
                            className="form-input secret-key-form-input edit-mentor-settings-form-input black-bg-white-color-form-field w-input"
                            custom-value="Personal"
                            data-name="Organisation Name 4"
                            id="mentor-name"
                            maxLength={256}
                            name="name"
                            value={mentor?.name}
                            onChange={handleChange}
                            placeholder="Mentor Name"
                            type="text"
                          />
                        </div>
                        <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block">
                          <div className="w-layout-hflex flex-block-19">
                            <label
                              className="form-input-label"
                              htmlFor="name-6"
                            >
                              {t('Description')}
                            </label>
                            <label
                              htmlFor="name-6"
                              className="form-input-label required"
                            >
                              *
                            </label>
                          </div>
                          <textarea
                            className="edit-mentor-settings-form-textarea black-bg-white-color-form-field textarea-medium-height w-input"
                            data-name="Field 8"
                            id="field-8"
                            maxLength={5000}
                            name="description"
                            value={mentor?.description}
                            onChange={handleChange}
                            placeholder="Mentor Description"
                          ></textarea>
                        </div>
                        <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block">
                          <div className="w-layout-hflex flex-block-19">
                            <label
                              htmlFor="name-6"
                              className="form-input-label"
                            >
                              {t('Category')}
                            </label>
                            <label
                              htmlFor="name-6"
                              className="form-input-label required"
                            >
                              *
                            </label>
                          </div>
                          <select
                            id="field-11"
                            name="category"
                            data-name="Field 11"
                            className="select-field-5 w-select"
                            onChange={handleChange}
                          >
                            <option value="">{t('Select a category')}</option>
                            {mentorCategories.map((category, index) => (
                              <option
                                key={`mentor-category-option-${index}`}
                                value={category?.name}
                                selected={
                                  category?.name === mentor?.metadata?.category
                                }
                              >
                                {category?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="w-layout-vflex flex-block-83">
                        <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block">
                          <div className="w-layout-hflex flex-block-19">
                            <label
                              className="form-input-label"
                              htmlFor="name-6"
                            >
                              {t('Image')}
                            </label>
                          </div>
                          <div
                            className={`w-layout-hflex mentor-image-uploader ${mentorImgSrc ? '' : 'empty'}`}
                            onClick={
                              mentorImgSrc
                                ? console.log
                                : handleTriggerFileUpload
                            }
                          >
                            {mentorImgSrc ? (
                              <div
                                className="w-layout-hflex upload-img-box"
                                data-w-id="3d85f56c-c65d-b956-7f38-bd18ab5e5ba8"
                                style={{
                                  background: `url(${mentorImgSrc})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: '50%',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '100%',
                                  height: '100%',
                                }}
                              >
                                <div className="w-layout-hflex upload-action-btn-container">
                                  <img
                                    alt=""
                                    className="upload-action-btn-icon view"
                                    loading="lazy"
                                    src="/images/eye-1.svg"
                                    style={{ display: 'none' }}
                                  />
                                  <img
                                    alt=""
                                    className="upload-action-btn-icon remove"
                                    loading="lazy"
                                    title="Remove image"
                                    onClick={handleRemoveImage}
                                    src="/images/trash-2.svg"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="w-layout-hflex upload-text-box">
                                <div className="text-block-52">
                                  {t('+ Upload')}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-hflex flex-block-20">
                      <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block horizontal-flex gap-20">
                        <div className="w-layout-hflex flex-block-19">
                          <label className="form-input-label" htmlFor="name-6">
                            {t('Featured Mentor')}
                          </label>
                        </div>
                        <div className="user-row-switcher-embed justify-start-flex w-embed">
                          <label className="user-switcher">
                            <input
                              onChange={handleCheckChange}
                              name="featured"
                              checked={mentor?.featured}
                              type="checkbox"
                            />
                            <span className="user-switcher-slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-embed">
                      <input
                        accept="image/*"
                        className="input-mentor-image-upload"
                        id="input-mentor-image-upload"
                        name="uploaded_profile_image"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
            {tab === 'prompts' && (
              <MentorPrompts
                creationMode={true}
                mentor={mentor}
                setMentor={setMentor}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <SubmitBtn
              onClick={
                !loading && isMentorFormValid() ? handleCreateMentor : () => {}
              }
              href="#"
              data-w-id="3d85f56c-c65d-b956-7f38-bd18ab5e5bb2"
              saving={loading}
              className={`${!isMentorFormValid() && 'disabled-btn'}`}
              style={{
                marginTop: tab === 'settings' ? '-70px' : 'unset',
                cursor: isMentorFormValid() ? 'pointer' : 'not-allowed',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Creatementormodalbox;
